import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'
import pt from 'vuetify/lib/locale/pt'
import fr from 'vuetify/lib/locale/fr'
import colors from 'vuetify/lib/util/colors'
import getBrowserLanguage from '@/plugins/i18n/helpers/getBrowserLanguage'

Vue.use(Vuetify)

const browserLanguage = getBrowserLanguage() // es || en || pt || fr || etc...
const languages = ['es', 'en', 'pt', 'fr']
const currentLanguage = languages.includes(browserLanguage)
  ? browserLanguage
  : 'en'

const opts = {
  lang: {
    locales: { es, en, pt, fr },
    current: currentLanguage
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0074FF',
        secondary: '#004284',
        accent: '#01FFE3',
        error: '#FE5F55',
        red: '#FE5F55',
        info: '#24A4ED',
        success: '#3CAF47',
        warning: colors.orange
      },
      dark: {
        primary: '#0074FF',
        secondary: '#004284',
        accent: '#FFBD6B',
        error: '#FE5F55',
        info: '#24A4ED',
        success: '#3CAF47',
        warning: colors.orange
      }
    },
    options: { customProperties: true }
  }
}
export default new Vuetify(opts)
