import {
  QUERY_PARAM_FROM,
  QUERY_PARAM_FROM_QRCODE,
  QUERY_PARAM_FROM_PREVIEW
} from '@/constants/queryParams.js'
import { DOMAIN_REGISTRATION_STATUS_ACTIVE } from '@/constants/domainRegistrationStatus.js'
import env from '@/env.js'

const state = () => ({})
const getters = {
  ecommerceDomain: (state, getters, rootState) => {
    const publicUrl = getters.defaultWebappUrl || ''
    const domains = publicUrl.match(/(\.[a-z]+){2,3}$/i) || []

    return domains[0] || ''
  },
  webappProtocol: (state, getters, rootState) => {
    if (!rootState.userCompany.userCompany) return ''
    const company = rootState.userCompany.userCompany
    const publicUrl = company.public_url
    const [protocol] = publicUrl.split('//')
    return protocol || 'https:'
  },
  defaultWebappUrl: (state, getters, rootState, rootGetters) => {
    const companyToken = rootGetters['userCompany/userCompanyToken']
    const host = env('APP_ENV') === 'prod' ? 'olaclick.menu' : 'fe.olaclick.dev'
    /**
     * usar el dominio olaclick.menu temporalmente
     * consultar a Morgan si aun debemos usarlo el 2024-08-01
     * lo normal seria retornar company.public_url
     */
    // return company?.public_url || null
    return `https://${companyToken}.${host}`
  },
  webappUrl: (state, getters, rootState, rootGetters) => {
    const userCompanyDomain = rootGetters['userCompany/userCompanyDomain']
    return userCompanyDomain?.registration_status === DOMAIN_REGISTRATION_STATUS_ACTIVE
      ? userCompanyDomain?.url
      : getters.defaultWebappUrl
  },
  webappUrlFromPreview: (state, getters) => {
    return `${getters.defaultWebappUrl}/?${QUERY_PARAM_FROM}=${QUERY_PARAM_FROM_PREVIEW}`
  },
  webappUrlFromQrcode: (state, getters) => {
    return `${getters.webappUrl}/?${QUERY_PARAM_FROM}=${QUERY_PARAM_FROM_QRCODE}`
  },
  defaultWebappProductsUrl: (state, getters, rootState, rootGetters) => {
    return `${getters.defaultWebappUrl}/products`
  },
  webappProductsUrl: (state, getters, rootState, rootGetters) => {
    const userCompanyDomain = rootGetters['userCompany/userCompanyDomain']
    return userCompanyDomain?.registration_status === DOMAIN_REGISTRATION_STATUS_ACTIVE
      ? `${userCompanyDomain?.url}/products`
      : getters.defaultWebappProductsUrl
  },
  webappProductsUrlFromPreview: (state, getters) => {
    return `${getters.defaultWebappProductsUrl}?${QUERY_PARAM_FROM}=${QUERY_PARAM_FROM_PREVIEW}`
  },
  webappProductsUrlFromQrcode: (state, getters) => {
    return `${getters.webappProductsUrl}?${QUERY_PARAM_FROM}=${QUERY_PARAM_FROM_QRCODE}`
  }
}
const actions = {}
const mutations = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
