export const QUERY_PARAM_COMPANY_TOKEN = 'u'
export const QUERY_PARAM_REDIRECT_TO = 'to'
export const QUERY_PARAM_FALLBACK = 'fallback'
export const QUERY_PARAM_RENEW = 'renew'

export const QUERY_PARAM_ADMIN = 'admin'

export const QUERY_PARAM_FROM = 'from'
export const QUERY_PARAM_FROM_QRCODE = 'qrcode'
export const QUERY_PARAM_FROM_PDF = 'pdf'
export const QUERY_PARAM_FROM_PREVIEW = 'preview'
